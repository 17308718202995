import React, { FC, useState, memo, useMemo } from 'react';
import styled from '@emotion/styled';
import { keyframes, useTheme } from '@emotion/react';
import * as Accordion from '@radix-ui/react-accordion';
import { IIndeksRetailFacetOutput } from '$models';
import { Flex } from '$components/layouts';
import { useTranslation } from '$hooks';
import { formatString, hexToRGB, mq } from '$lib/helpers';
import { circle, flexCenter, hoverUnderline } from '$lib/style-helpers';
import { Text } from '$components/elements/text';
import { SidePanel } from '$components/elements/side-panel';
import { Checkbox } from '$components/elements/checkbox';
import { SvgIcon } from '$components/elements/svg-icon';
import { Button } from '$components/elements/button';
import { Responsive } from '$components/elements/responsive';
import { FILTER_KEY_PRICE_HELLO_RETAIL, SORTING_FACET_KEY, defaultHelloRetailSortOption } from '../../helpers';
import { FacetSelector } from '../facet-selector/facet-selector';
import { IHelloRetailFilterSelections, IHelloRetailSortingOption } from '~/modules/search/interfaces/suggestion';
import { HelloRetailProductListHeader } from './hello-retail-product-list-header';

const CTA_BREAKPOINT = 500;

type Props = {
    facets?: IIndeksRetailFacetOutput[];
    selectedFacets?: IHelloRetailFilterSelections;
    totalResults?: number;
    sortingOptions?: IHelloRetailSortingOption[];
    openOverwrite?: boolean;
    hideOnMobile?: boolean;
    hideOnDesktop?: boolean;
    onSetOpenOverwrite?: (open: boolean) => void;
    onSelectFacet?: (facets: IHelloRetailFilterSelections) => void;
    onConfirm?: (facets: IHelloRetailFilterSelections) => void;
};

export const HelloRetailFacetControls: FC<Props> = memo(
    ({
        facets,
        sortingOptions,
        selectedFacets = {},
        totalResults,
        openOverwrite,
        hideOnMobile,
        hideOnDesktop,
        onSetOpenOverwrite,
        onSelectFacet,
        onConfirm,
    }: Props) => {
        const { translate } = useTranslation();
        const theme = useTheme();
        const [filterControlsState, setFilterControlsState] = useState<{ show: boolean; initatorKey?: string }>({
            show: false,
        });

        const selectedSorting = useMemo(() => {
            const sorting = sortingOptions?.find((so) => so.selected);
            if (sorting) {
                return sorting;
            } else {
                return sortingOptions?.[0];
            }
        }, [sortingOptions]);

        const resetFilters = () =>
            onConfirm?.({
                sorting: defaultHelloRetailSortOption,
            });

        const toggleFacet = (facetKey?: string, facetValue?: string, confirmSelection?: boolean) => {
            if (!facetKey || (!facetValue && facetKey !== FILTER_KEY_PRICE_HELLO_RETAIL)) {
                return;
            }

            let updatedSelection = [...(selectedFacets.selectedFacets || [])];

            if (facetKey === FILTER_KEY_PRICE_HELLO_RETAIL) {
                if (!facetValue) {
                    updatedSelection = updatedSelection.filter((f) => f.key !== facetKey);
                } else {
                    const facetIndex = updatedSelection.findIndex((f) => f.key === FILTER_KEY_PRICE_HELLO_RETAIL);
                    if (facetIndex > -1) {
                        updatedSelection[facetIndex] = { key: facetKey, query: facetValue };
                    } else {
                        updatedSelection.push({ key: facetKey, query: facetValue });
                    }
                }
            } else {
                const hasFacet = updatedSelection.find((f) => f.query === facetValue);

                if (hasFacet) {
                    updatedSelection = updatedSelection.filter((f) => f.query !== facetValue);
                } else {
                    updatedSelection.push({ key: facetKey, query: facetValue });
                }
            }

            if (confirmSelection) {
                onConfirm?.({ sorting: selectedSorting, selectedFacets: updatedSelection });
            } else {
                onSelectFacet?.({ sorting: selectedSorting, selectedFacets: updatedSelection });
            }
        };

        const toggleSorting = (sorting?: IHelloRetailSortingOption) => {
            if (sorting) {
                onSelectFacet?.({
                    sorting: sorting,
                    selectedFacets: selectedFacets.selectedFacets,
                });
            }
        };

        return (
            <>
                <HelloRetailProductListHeader
                    onFacetGroupSelected={(facetKey) => {
                        setFilterControlsState({ show: true, initatorKey: facetKey });
                        onSetOpenOverwrite?.(true);
                    }}
                    facets={facets}
                    onResetAllFacets={resetFilters}
                    totalResults={totalResults}
                    selectedSorting={selectedSorting}
                    onFacetToggled={(key, value) => toggleFacet(key, value, true)}
                    hideOnMobile={hideOnMobile}
                    hideOnDesktop={hideOnDesktop}
                />
                <SidePanel
                    title={translate('search.filterAndFacets.allFilters')}
                    show={openOverwrite !== undefined ? openOverwrite : filterControlsState.show}
                    maxWidth={505}
                    onChange={(open: boolean) => {
                        setFilterControlsState((prev) => ({
                            show: open,
                            initiatorKey: open ? prev.initatorKey : undefined,
                        }));
                        onSetOpenOverwrite?.(open);
                    }}
                >
                    <FacetAccordion
                        type="multiple"
                        defaultValue={filterControlsState.initatorKey ? [filterControlsState.initatorKey] : undefined}
                    >
                        {selectedSorting && (
                            <AccordionItem key={'[sorting]'} value={SORTING_FACET_KEY}>
                                <FacetHeader>
                                    <Text>{translate('search.filterAndFacets.sorting')}</Text>
                                    <Flex gap="small" alignItems="center">
                                        <Text dimmed>{selectedSorting.title}</Text>
                                        <AccordionChevron svg="chevronDown" size={24} aria-hidden />
                                    </Flex>
                                </FacetHeader>
                                <AccordionContent>
                                    {sortingOptions?.map((so) => (
                                        <Facet key={so.query}>
                                            <Checkbox
                                                label={so.title}
                                                type="radio"
                                                checked={so.selected}
                                                onChange={() => {
                                                    toggleSorting(so);
                                                }}
                                            />
                                        </Facet>
                                    ))}
                                </AccordionContent>
                            </AccordionItem>
                        )}
                        {facets?.map((f) => {
                            const activeFacets = f.facetResults
                                ?.map((f) => (f.isSelected ? 1 : 0))
                                ?.reduce((a: number, c: number) => a + c, 0);

                            return (
                                <AccordionItem key={f.key} value={`${f.key}`}>
                                    <FacetHeader>
                                        <Text color={f.isSelected ? theme.colors.primary : undefined}>{f.name}</Text>
                                        <Flex gap="small" alignItems="center">
                                            {!!activeFacets && (
                                                <ActiveFacetCount>
                                                    <Text size="11px">{activeFacets}</Text>
                                                </ActiveFacetCount>
                                            )}
                                            <AccordionChevron svg="chevronDown" size={24} aria-hidden />
                                        </Flex>
                                    </FacetHeader>
                                    <AccordionContent>
                                        <FacetSelector onFacetToggled={toggleFacet} facet={f} />
                                    </AccordionContent>
                                </AccordionItem>
                            );
                        })}
                    </FacetAccordion>
                    <CTAContainer>
                        <Responsive min={CTA_BREAKPOINT}>
                            <CTA variant="tertiary" onClick={resetFilters}>
                                {translate('search.filterAndFacets.resetFilter')}
                            </CTA>
                        </Responsive>
                        <Responsive max={CTA_BREAKPOINT}>
                            <ResetCTAMobile onClick={resetFilters}>
                                <span>{translate('search.filterAndFacets.resetFilter')}</span>
                            </ResetCTAMobile>
                        </Responsive>
                        <CTA
                            disabled={!totalResults}
                            onClick={() => {
                                onConfirm?.(selectedFacets);
                                onConfirm?.({
                                    sorting: selectedSorting,
                                    selectedFacets: selectedFacets.selectedFacets,
                                });
                                setFilterControlsState({ show: false });
                                onSetOpenOverwrite?.(false);
                            }}
                        >
                            {formatString(translate('search.filterAndFacets.expectedResults'), totalResults || 0)}
                            {/* {loading
                                ? 'loading'
                                : formatString(
                                      translate('search.filterAndFacets.expectedResults'),
                                      totalResults || 0
                                  )} */}
                        </CTA>
                    </CTAContainer>
                </SidePanel>
            </>
        );
    }
);

const AccordionChevron = styled(SvgIcon)({
    transition: 'transform 200ms',
    transform: 'rotate(0)',

    '[data-state=open] > &': {
        transform: 'rotate(180deg)',
    },
});

const slideDown = keyframes({
    from: { height: 0 },
    to: { height: 'var(--radix-accordion-content-height)' },
});

const slideUp = keyframes({
    from: { height: 'var(--radix-accordion-content-height)' },
    to: { height: 0 },
});

const AccordionItem = styled(Accordion.Item)(({ theme }) => ({
    borderBottom: `1px solid ${hexToRGB(theme.colors.primaryGrey, 0.3)}`,

    ':last-of-type': {
        border: 'none',
    },
}));

const AccordionContent = styled(Accordion.Content)(({ theme }) => ({
    overflow: 'hidden',
    padding: `0 ${theme.space[9]} ${theme.space[9]}`,

    [mq(0, 'sm')]: {
        padding: `0 ${theme.space[6]} ${theme.space[9]}`,
    },

    '&[data-state="open"]': {
        animation: `${slideDown} 200ms ease-out`,
    },
    '&[data-state="closed"]': {
        animation: `${slideUp} 200ms ease-out`,
    },
}));

const Facet = styled.div(({ theme }) => ({
    '&:not(:last-of-type)': {
        marginBottom: theme.space[5],
    },
}));

const FacetAccordion = styled(Accordion.Root)(({ theme: { space } }) => ({
    all: 'unset',
    flex: 1,
    overflowY: 'auto',
    paddingBottom: space[10],
}));

const FacetHeader = styled(Accordion.Trigger)(({ theme }) => ({
    all: 'unset',
    ...theme.mixins.useTextStyle('button'),
    cursor: 'pointer',
    height: '71px',
    width: `calc(100% - ${theme.space[9]} - ${theme.space[9]})`,
    padding: `0 ${theme.space[9]}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    [mq(0, 'sm')]: {
        width: `calc(100% - ${theme.space[6]} - ${theme.space[6]})`,
        padding: `0 ${theme.space[6]}`,
    },
}));

const ActiveFacetCount = styled.div(({ theme }) => ({
    ...circle(18),
    ...flexCenter,
    background: theme.colors.black,
    color: theme.colors.white,
}));

const CTA = styled(Button)({
    whiteSpace: 'nowrap',
    ':last-of-type': {
        [mq(CTA_BREAKPOINT)]: {
            flex: 1,
        },
    },
});

const CTAContainer = styled.div(({ theme }) => ({
    alignItems: 'center',
    background: theme.colors.white,
    borderTop: `1px solid ${hexToRGB(theme.colors.primaryGrey, 0.3)}`,
    display: 'flex',
    height: '82px',
    padding: `0 ${theme.space[9]}`,
    marginTop: 'auto',
    zIndex: 2,

    [mq(0, 'sm')]: {
        paddingRight: theme.space[6],
        paddingLeft: theme.space[6],
    },

    [mq(0, CTA_BREAKPOINT)]: {
        flexDirection: 'column-reverse',
        alignItems: 'stretch',
        height: '102px',
        paddingTop: theme.space[1],
        paddingBottom: theme.space[1],
    },

    [mq(CTA_BREAKPOINT)]: {
        [`& > ${CTA}`]: {
            marginLeft: theme.space[6],
        },
    },
}));

const ResetCTAMobile = styled.button({
    all: 'unset',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    height: '40px',

    span: {
        ...hoverUnderline(),
    },
});
