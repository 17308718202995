import React, { memo, useMemo } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { ifProp } from 'styled-tools';
import { IIndeksRetailFacetOutput } from '$models';
import { Flex } from '$components/layouts';
import { useTranslation } from '$hooks';
import { formatString, mq, isDefined } from '$lib/helpers';

import { Text } from '$components/elements/text';
import { SvgIcon } from '$components/elements/svg-icon';
import { Responsive } from '$components/elements/responsive';
import { IHelloRetailSortingOption } from '~/modules/search/interfaces/suggestion';
import { FilterControlTypes, SORTING_FACET_KEY } from '../../helpers';

type Props = {
    facets?: IIndeksRetailFacetOutput[];
    selectedSorting?: IHelloRetailSortingOption;
    totalResults?: number;
    hideOnMobile?: boolean;
    hideOnDesktop?: boolean;
    onFacetGroupSelected?: (facetKey?: string) => void;
    onFacetToggled?: (facetKey?: string, facetValue?: string) => void;
    onResetAllFacets?: () => void;
};

export const HelloRetailProductListHeader = memo(
    ({
        facets,
        hideOnMobile,
        hideOnDesktop,
        onFacetGroupSelected,
        onFacetToggled,
        totalResults,
        selectedSorting,
        onResetAllFacets,
    }: Props) => {
        const { translate } = useTranslation();
        const theme = useTheme();

        const sortingLabel = selectedSorting?.title;

        const facetsAreActive = useMemo(
            () => facets?.find((f) => f.isSelected || f.facetResults?.some((fr) => fr.isSelected)),
            [facets]
        );

        if (!selectedSorting && !isDefined(totalResults)) {
            return <></>;
        }

        return (
            <ProductListHeaderContainer column hideOnMobile={hideOnMobile} hideOnDesktop={hideOnDesktop}>
                <FacetDashboard justifyContent="space-between" alignItems="center">
                    <HeaderFacetContainer>
                        <FacetOpener key="all-filters" onClick={() => onFacetGroupSelected?.()}>
                            <SvgIcon svg="equalizer" rotate={90} className="all-filters-icon" />
                            {translate('search.filterAndFacets.allFilters')}
                        </FacetOpener>
                        {facets?.map((facet) => (
                            <FacetOpener
                                desktopOnly
                                key={facet.key}
                                active={facet.isSelected}
                                onClick={() => facet.key && onFacetGroupSelected && onFacetGroupSelected(facet.key)}
                            >
                                {facet.name}
                            </FacetOpener>
                        ))}
                    </HeaderFacetContainer>
                    <HeaderSortingContainer>
                        <DesktopTotal min="ml">
                            <TotalText>
                                {formatString(translate('search.filterAndFacets.productsFound'), totalResults || 0)}
                            </TotalText>
                        </DesktopTotal>
                        <FacetOpener onClick={() => onFacetGroupSelected?.(SORTING_FACET_KEY)}>
                            <SvgIcon svg="sort" />
                            <ControlLabel style={{ marginLeft: theme.space[2] }}>
                                <ControlLabel highlight>{sortingLabel}</ControlLabel>
                            </ControlLabel>
                        </FacetOpener>
                    </HeaderSortingContainer>
                </FacetDashboard>
                <MobileTotal max="ml">
                    <TotalText>
                        {formatString(translate('search.filterAndFacets.productsFound'), totalResults || 0)}
                    </TotalText>
                </MobileTotal>

                {facets?.some((f) => f.isSelected) && (
                    <ActiveFacets>
                        {facets
                            ?.map((facet) => {
                                if (facet.control === FilterControlTypes.Slider && (facet as any).isSelected) {
                                    return (
                                        <FacetChip
                                            key={facet?.key}
                                            onClick={() => onFacetToggled && onFacetToggled(facet?.key, undefined)}
                                        >
                                            <SvgIcon svg="cross" />
                                            <span>{`${facet.currentMin} DKK - ${facet.currentMax} DKK`}</span>
                                        </FacetChip>
                                    );
                                } else {
                                    const facetResults = facet.facetResults?.filter((fr) => fr.isSelected) || [];
                                    return facetResults.map((activeFacet) => (
                                        <FacetChip
                                            key={activeFacet?.query?.value}
                                            onClick={() =>
                                                onFacetToggled && onFacetToggled(facet?.key, activeFacet?.query?.value)
                                            }
                                        >
                                            <SvgIcon svg="cross" />
                                            <span>{activeFacet?.query?.name}</span>
                                        </FacetChip>
                                    ));
                                }
                            })
                            .flat()}
                        {facetsAreActive && (
                            <FacetChip onClick={onResetAllFacets} alternative>
                                <span>{translate('search.filterAndFacets.resetFilter')}</span>
                            </FacetChip>
                        )}
                    </ActiveFacets>
                )}
            </ProductListHeaderContainer>
        );
    }
);

const ProductListHeaderContainer = styled(Flex)<{ hideOnMobile?: boolean; hideOnDesktop?: boolean }>(
    ({ hideOnMobile, hideOnDesktop, theme }) => ({
        margin: `${theme.space[3]} 0 ${theme.space[7]}`,

        '& > span': {
            display: 'inline-block',
            margin: '0 auto',
            marginTop: theme.space[3],

            [mq(0, 'sm')]: {
                display: 'none',
            },
        },
        ...(hideOnMobile && {
            [mq(0, 'md')]: {
                display: 'none',
            },
        }),
        ...(hideOnDesktop && {
            [mq('md')]: {
                display: 'none',
            },
        }),
    })
);

const FacetDashboard = styled(Flex)({
    alignItems: 'flex-start',
    overflow: 'hidden',

    '& > span': {
        display: 'none',
        [mq('sm')]: {
            display: 'inline-block',
        },
    },
});

const HeaderFacetContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    margin: `-${theme.space[1]}`,

    [mq(0, 'ml')]: {
        flex: 1,
    },
}));

const HeaderSortingContainer = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.space[4],
    margin: `-${theme.space[1]}`,

    [mq(0, 'ml')]: {
        flex: 1,
    },
}));

const ActiveFacets = styled(Flex)(({ theme }) => ({
    display: 'none',
    marginTop: theme.space[4],

    [mq('md')]: {
        display: 'flex',
    },
}));

const ControlLabel = styled.span<{ highlight?: boolean }>(({ highlight, theme }) => ({
    fontWeight: highlight ? theme.fontWeights.medium : theme.fontWeights.regular,
}));

const FacetChip = styled.button<{ alternative?: boolean }>(
    ({ theme }) => ({
        all: 'unset',
        alignItems: 'center',
        border: `1px solid ${theme.colors.grey15}`,
        borderRadius: 15,
        boxSizing: 'border-box',
        cursor: 'pointer',
        display: 'flex',
        fontSize: theme.fontSizes.xxs,
        height: 30,
        padding: '0 12px',
        whiteSpace: 'nowrap',

        '&:not(:last-of-type)': { marginRight: theme.space[2] },

        span: {
            marginLeft: theme.space[1],
        },
    }),
    ifProp('alternative', ({ theme }) => ({
        background: theme.colors.grey5,
        borderColor: theme.colors.grey5,
        span: {
            marginLeft: 0,
        },
    }))
);

const FacetOpener = styled.button<{ active?: boolean; desktopOnly?: boolean }>(
    ({ theme }) => ({
        all: 'unset',
        alignItems: 'center',
        border: `1px solid ${theme.colors.grey15}`,
        borderRadius: 20,
        boxSizing: 'border-box',
        cursor: 'pointer',
        display: 'flex',
        fontSize: theme.fontSizes.xs,
        fontWeight: theme.fontWeights.medium,
        height: 40,
        justifyContent: 'center',
        padding: `0 18px`,
        whiteSpace: 'nowrap',
        margin: theme.space[1],

        '.all-filters-icon': {
            marginRight: theme.space[2],
        },

        ':hover': {
            borderColor: theme.colors.black,
        },

        [mq(0, 'ml')]: {
            width: '100%',
        },
    }),
    ifProp('active', ({ theme }) => ({
        borderColor: theme.colors.black,
    })),
    ifProp('desktopOnly', {
        display: 'none',
        [mq('ml')]: {
            display: 'flex',
        },
    })
);

const DesktopTotal = styled(Responsive)(({ theme }) => ({
    marginRight: theme.space[4],
}));

const MobileTotal = styled(Responsive)(({ theme }) => ({
    padding: theme.space[4],
    margin: '0 auto',
}));

const TotalText = styled(Text)(({ theme }) => ({
    textTransform: 'lowercase',
    color: theme.colors.grey,
    fontSize: theme.fontSizes.sm,
    whiteSpace: 'nowrap',
}));
